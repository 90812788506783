//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    type: { type: String, default: '' },
    command: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('reports', {
      createReportAction: 'createReport',
    }),

    async createReport() {
      const { type, command } = this;
      this.setGeneralProgress(true);
      try {
        const { fileName } = await this.createReportAction({
          type,
          data: {
            ...command,
            limit: constant.reports.LIMIT,
            offset: 0,
          },
        });
        this.confirmAction({
          title: this.$t('common.Request created'),
          text: this.$t('common.Request for report has been created', { fileName }),
          hasCancelButton: false,
        });
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
