export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Total deposit',
      sort: 'totalDeposit',
    },
    cell: {
      type: 'text',
      value: (e) => e.totalDeposit,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Total deposit fee',
    },
    cell: {
      type: 'text',
      value: (e) => e.depositProfit,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Total withdrawal',
      sort: 'totalWithdrawal',
    },
    cell: {
      type: 'text',
      value: (e) => e.totalWithdrawal,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Total withdrawal fee',
    },
    cell: {
      type: 'text',
      value: (e) => e.withdrawalProfit,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Deposit count',
      sort: 'depositCount',
    },
    cell: {
      type: 'text',
      value: (e) => e.depositCount,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Withdrawal count',
      sort: 'withdrawalCount',
    },
    cell: {
      type: 'text',
      value: (e) => e.withdrawalCount,
    },
  },
];
