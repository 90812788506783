//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Table from '~/components/ui/Table.vue';
import TransactionsStatisticsFilters from './TransactionsStatisticsFilters.vue';
import GetReport from '~/components/GetReport.vue';
import tableConfig from './transactionsStatisticsTableConfig';
import constant from '~/const';

export default {

  components: {
    Table,
    TransactionsStatisticsFilters,
    GetReport,
  },
  data() {
    return {
      loader: false,
      tableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('currencies', ['currencies']),
    ...mapState('transactionsStatistics', [
      'transactionsStatistics',
      'sort',
    ]),
    ...mapGetters('transactionsStatistics', ['requestData']),
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.getTransactionStatsAction(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('transactionsStatistics', {
      getTransactionStatsAction: 'getTransactionStats',
      setSortAction: 'setSort',
    }),
    ...mapActions('common', ['setGeneralLoader']),

    async getTransactionStats(data = {}) {
      this.loader = true;
      await this.getTransactionStatsAction(data);
      this.loader = false;
    },

    applyFilters() {
      this.getTransactionStats();
    },

    applySort(data) {
      this.setSortAction(data);
      this.getTransactionStats();
    },
  },
};
