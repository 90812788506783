//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from '~/components/filters/Index.vue';
import TextField from '~/components/ui/TextField.vue';
import Select from '~/components/ui/Select.vue';
import constant from '~/const';

export default {

  components: {
    Filters,
    TextField,
    Select,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      currencyType: undefined,
      filters: {
        searchString: '',
        currencyIdList: null,
      },
      currencySelect: [],
      currencyTypes: [
        { id: constant.currencies.CURRENCY_TYPE.FIAT, caption: 'Fiat' },
        { id: constant.currencies.CURRENCY_TYPE.CRYPTO, caption: 'Crypto' },
      ],
    };
  },

  computed: {
    ...mapState('transactionsStatistics', ['filterLists']),
    ...mapGetters('transactionsStatistics', ['currencyTypeList', 'currencyList']),

    currencyListByType() {
      if (!this.currencyType && this.currencyType !== 0) {
        return this.currencyList;
      }
      return this.currencyList.filter((e) => e.currencyType === this.currencyType);
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('transactionsStatistics', ['setFilters']),

    currencyTypeInputHandler(event) {
      if (event === constant.currencies.CURRENCY_TYPE.FIAT || event === constant.currencies.CURRENCY_TYPE.CRYPTO) {
        const currenciesByType = this.currencyList
          .filter((e) => e.currencyType === event)
          .map((e) => e.id);
        if (this.currencySelect.length) {
          this.currencySelect = this.currencySelect.filter((selectedCurrencyId) => currenciesByType.includes(selectedCurrencyId));
          if (
            !(this.filters.currencyIdList.every((id) => this.currencySelect.includes(id))
            && this.filters.currencyIdList.length === this.currencySelect.length)
          ) {
            this.filters.currencyIdList = [...this.currencySelect];
          }
        } else {
          this.filters.currencyIdList = currenciesByType;
        }
      } else if (!this.currencySelect.length) {
        this.filters.currencyIdList = [];
      }
    },

    currencySelectBlurHandler() {
      this.filters.currencyIdList = [...this.currencySelect];
    },

    currencySelectInputHandler(currencies) {
      if (!currencies || currencies.length === 0) {
        this.filters.currencyIdList = [];
      }
    },

    wrapperFiltersInputHandler(data) {
      if (Object.values(data).every((value) => !value)) {
        this.currencySelect = [];
        this.currencyType = undefined;
      }
    },
  },
};
